<template>
  <div class="card border-radius" style="border-color: rgb(234, 236, 240)">
    <div class="card-body">
      <div class="compare_feature_container toggle_feature">
        <div class="compare_feature_wrap">
          <div class="tier-header-wrap">
            <div class="tier-header"></div>
            <div class="tier-header">
              <div class="tier_title">
                <h3>Lite</h3>
              </div>
              <div class="tier_price"> {{ planPrices.lite?'$ '+planPrices.lite+'/mth': '-' }}</div>
              <div class="tier_button">
                <button v-if=planPrices.lite :disabled="allPlans?.lite==user.planId"
                  @click="openPlan(allPlans?.lite)"
                 
                >
                {{allPlans?.lite==user.planId?'Selected':'Upgrade' }}
                </button>
              </div>
            </div>
            <div class="tier-header ">
              <div class="tier_title">
                <h3>Plus</h3>
              </div>
              <div class="tier_price"> {{ planPrices.plus?'$ '+planPrices.plus+'/mth': '-' }}</div>
              <div class="tier_button">
                <button :disabled="allPlans?.plus==user.planId"
                   @click="openPlan(allPlans?.plus)"
                  
                >
                  {{allPlans?.plus==user.planId?'Selected':'Upgrade' }}
                </button>
              </div>
            </div>
            <div class="tier-header tier-header-5" style="position:relative">
              <div class="tier_title">
                <h3>Pro</h3>
              </div>
              <div class="tier_price" > {{ planPrices.pro?'$ '+planPrices.pro+'/mth': '-' }}</div>
              <div class="tier_button">
                <button :disabled="allPlans?.pro==user.planId"
                  @click="openPlan(allPlans?.pro)"
                 
                >
                {{allPlans?.lite==user.planId?'Selected':'Upgrade' }}
                </button>
              </div>
            </div>
            <div class="tier-header">
              <div class="tier_title">
                <h3>Enterprise</h3>
              </div>
              <div class="tier_price">Custom</div>
              <div class="tier_button">
                <a href="https://quizell.com/pages/enterprise" target="_blank" style="background-color: #2a2a34;color:white;">
                  Contact Us
                </a>
              </div>
            </div>
          </div>


          <div
              class="feature_content"
            
            >
              <div class="feature_content_wrap"  style="background: white;">
                <div class="section_name">
              <h2 class="ml-1">Essentials</h2>
            </div>
               
                <div class="lite_feature_value feature_value">
                 
                </div>
                <div class="plus_feature_value feature_value">
                
                </div>
                <div class="pro_feature_value feature_value pro_feature_value">
                 
                </div>
                <div class="enterprise_feature_value feature_value">
                 
                </div>
              </div>
            </div>
          <div>
            <div
              class="feature_content"
              v-for="(essential, key) in plans.Essentials"
              :key="key"
            >
              <div class="feature_content_wrap">
                <div class="feature_name">
                  <h4>{{ camelCaseToWords(key) }}</h4>
                </div>
               
                <div class="lite_feature_value feature_value">
                  <span v-if="typeof essential.lite == 'string'">{{
                    essential.lite
                  }}</span
                  ><span v-else-if="essential.lite" v-html="tickSVG"></span
                  ><span v-else>-</span>
                </div>
                <div class="plus_feature_value feature_value">
                  <span v-if="typeof essential.plus == 'string'">{{
                    essential.plus
                  }}</span
                  ><span v-else-if="essential.plus" v-html="tickSVG"></span
                  ><span v-else>-</span>
                </div>
                <div class="pro_feature_value feature_value pro_feature_value">
                  <span v-if="typeof essential.pro == 'string'">{{
                    essential.pro
                  }}</span
                  ><span v-else-if="essential.pro" v-html="tickSVG"></span
                  ><span v-else>-</span>
                </div>
                <div class="enterprise_feature_value feature_value">
                  <span v-if="typeof essential.enterprise == 'string'">{{
                    essential.enterprise
                  }}</span
                  ><span
                    v-else-if="essential.enterprise"
                    v-html="tickSVG"
                  ></span
                  ><span v-else>-</span>
                </div>
              </div>
            </div>
          </div>
          <div
              class="feature_content"
            
            >
              <div class="feature_content_wrap"  style="background: white;">
                <div class="section_name">
              <h2 class="ml-1">Integrations</h2>
            </div>
               
                <div class="lite_feature_value feature_value">
                 
                </div>
                <div class="plus_feature_value feature_value">
                
                </div>
                <div class="pro_feature_value feature_value pro_feature_value">
                 
                </div>
                <div class="enterprise_feature_value feature_value">
                 
                </div>
              </div>
            </div>
          <div
            class="feature_content-with-icon"
            v-for="(integrations, key) in plans.Integrations"
            :key="key"
          >
            <div class="feature_content_wrap">
              <div class="feature_name text-capitalize">
                <h4>{{ key }}</h4>
              </div>

             
              <div class="write-symbol">
                <span v-if="integrations.lite" v-html="tickSVG"></span>
                <span v-else>-</span>
              </div>

              <div class="write-symbol ">
                <span v-if="integrations.plus" v-html="tickSVG"></span>
                
                <span v-else>-</span>
                </div>
                  <div class="write-symbol pro_write-symbol">
                    <span v-if="integrations.pro" v-html="tickSVG"></span>
                    <span v-else>-</span>
                  </div>
              <div class="write-symbol">
                <span v-if="integrations.enterprise" v-html="tickSVG"></span>
                <span v-else>-</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    price:Array,
    mode:String,
    user:Object
  },
  computed: {
    // We create a computed property to map plan names to their prices
    planPrices() {
      const prices = {};
      this.price.forEach(plan => {
        prices[plan.name.toLowerCase()] = this.mode == 'yearly'?plan.payment_amount/12:plan.payment_amount;
      });

      return prices;
    },

    allPlans() {
      const planMap = {};
      this.price.forEach(plan => {
        planMap[plan.name.toLowerCase()] = plan.id;
      });
      return planMap;
    }
  },
  data() {
    return {
      plans: {
  "Essentials": {
    "quizzes": {
      "lite": "1 Quiz",
      "plus": "4 Quizzes",
      "pro": "10 Quizzes",
      "enterprise": "Unlimited"
    },
    "engagements": {
      "lite": "500 / Month",
      "plus": "4000 / Month",
      "pro": "20000 / Month",
      "enterprise": "Custom"
    },
    "emails": {
      "lite": "50 / Month",
      "plus": "1000 / Month",
      "pro": "5000 / Month",
      "enterprise": "Custom"
    },
    "productLimitations": {
      "lite": "Up to 250 products",
      "plus": "Up to 1000 products",
      "pro": "Up to 4000 products",
      "enterprise": "Custom"
    },
    "questionsLimitations": {
      "lite": "Unlimited",
      "plus": "Unlimited",
      "pro": "Unlimited",
      "enterprise": "Unlimited"
    },
    "removeWatermark": {
      "lite": true,
      "plus": true,
      "pro": true,
      "enterprise": true
    },
    "otpAuth": {
      "lite": false,
      "plus": true,
      "pro": true,
      "enterprise": true
    },
    "webhooks": {
      "lite": false,
      "plus": true,
      "pro": true,
      "enterprise": true
    },
    "scripts": {
      "lite": false,
      "plus": false,
      "pro": true,
      "enterprise": true
    },
    "fileUpload": {
      "lite": false,
      "plus": true,
      "pro": true,
      "enterprise": true
    },
    "addToCartShopify": {
      "lite": false,
      "plus": true,
      "pro": true,
      "enterprise": true
    },
    "timer": {
      "lite": false,
      "plus": true,
      "pro": true,
      "enterprise": true
    },
    "utm": {
      "lite": true,
      "plus": true,
      "pro": true,
      "enterprise": true
    }
  },
  "Integrations": {
    "bigCommerce": {
      "lite": true,
      "plus": true,
      "pro": true,
      "enterprise": true
    },
    "hubSpot": {
      "lite": true,
      "plus": true,
      "pro": true,
      "enterprise": true
    },
    "judgeMe": {
      "lite": true,
      "plus": true,
      "pro": true,
      "enterprise": true
    },
    "shopify": {
      "lite": true,
      "plus": true,
      "pro": true,
      "enterprise": true
    },
    "stripe": {
      "lite": false,
      "plus": true,
      "pro": true,
      "enterprise": true
    },
    "upPromote": {
      "lite": true,
      "plus": true,
      "pro": true,
      "enterprise": true
    },
    "wooCommerce": {
      "lite": true,
      "plus": true,
      "pro": true,
      "enterprise": true
    },
    "zapier": {
      "lite": true,
      "plus": true,
      "pro": true,
      "enterprise": true
    },
    "klaviyo": {
      "lite": true,
      "plus": true,
      "pro": true,
      "enterprise": true
    },
    "mailchimp": {
      "lite": true,
      "plus": true,
      "pro": true,
      "enterprise": true
    },
    "mailLite": {
      "lite": true,
      "plus": true,
      "pro": true,
      "enterprise": true
    },
    "omnisend": {
      "lite": true,
      "plus": true,
      "pro": true,
      "enterprise": true
    },
    "activeCampaign": {
      "lite": true,
      "plus": true,
      "pro": true,
      "enterprise": true
    }
  }
},
      tickSVG: ` <svg width="25" v-if="integrations.free" height="24" viewBox="0 0 25 24" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_48_2703)">
                    <rect x="0.666748" width="24" height="24" rx="12" fill="#F3E7FF" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M17.7633 7.38967L10.6033 14.2997L8.70325 12.2697C8.35325 11.9397 7.80325 11.9197 7.40325 12.1997C7.01325 12.4897 6.90325 12.9997 7.14325 13.4097L9.39325 17.0697C9.61325 17.4097 9.99325 17.6197 10.4233 17.6197C10.8333 17.6197 11.2233 17.4097 11.4433 17.0697C11.8033 16.5997 18.6733 8.40967 18.6733 8.40967C19.5733 7.48967 18.4833 6.67967 17.7633 7.37967V7.38967Z"
                      fill="#9140E1" />
                  </g>
                  <defs>
                    <clipPath id="clip0_48_2703">
                      <rect x="0.666748" width="24" height="24" rx="12" fill="white" />
                    </clipPath>
                  </defs>
                </svg>`,
    };
  },
  methods: {
    openPlan(planId) {
      const plan = this.price.find(p => p.id == planId);
      this.$emit("openPlanFunc", plan);
    },
    camelCaseToWords(string) {
      return string
        .replace(/([a-z0-9])([A-Z])/g, "$1 $2") // Add space before uppercase letters
        .replace(/^./, function (str) {
          return str.toUpperCase();
        }); // Capitalize the first letter
    },
  },
};
</script>

<style>
.product-feature-list .compare_feature_container.toggle_feature {
  display: block;
}

.tier-header-wrap {
  display: grid;
  grid-template-columns: 1.6fr repeat(4, 1fr);
  /* position: sticky; */
  top: 62px;
  background: #fff;
}

.tier-header {
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 2;
  padding: 16px 0;
}

.section_name {


  display: flex;
  -webkit-box-pack: start;
  justify-content: flex-start;
  padding: 8px 0;
  /* border-bottom: 1px solid rgb(230, 233, 239); */
}

.tier-header .tier_title h3 {
 
font-size: 20px;
font-weight: 500;
line-height: 24px;
text-align: center;
font-size: 16px;
  color:#667085;
}

.tier-header .tier_button button,.tier-header .tier_button a {
  padding: 10px 18px 10px 18px;
    border-radius: 99px;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    box-shadow: 0px 1px 2px 0px #1018280D;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    transition: 0.3s ease all;
    text-decoration: none;
    background: white;
    display: block;
}
.tier-header .tier_button button:disabled{
  pointer-events: none;
  opacity: 0.8;
  cursor:not-allowed;
  color: var(--white);
  background: var(--primary-color);
}
.tier-header .tier_button button:not(:disabled):hover{
  color: var(--white);
  background: var(--primary-color);
}

.compare_feature_wrap .section_name h2 {
  font-size: 14px;
font-weight: 500;
line-height: 22px;
color: var(--primary-color);
}

.feature_content_wrap {
  display: grid;
  grid-template-columns: 1.6fr repeat(4, 1fr);
}

.compare_feature_wrap .feature_name h4 {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  padding: 32px 0;
 color: #292D35;
font-size: 14px;
font-weight: 500;
line-height: 22px;
margin:0px;
padding-left: 8px;

}

.feature_content-with-icon:nth-child(even),.feature_content:nth-child(even) {
  background: rgba(249, 250, 251, 1);
}

.feature_content-with-icon:nth-child(odd),.feature_content:nth-child(odd) {
  background: whitesmoke;
}
.feature_content:nth-child(odd) .feature_content_wrap .feature_name,
.feature_content-with-icon:nth-child(odd) .feature_content_wrap .feature_name{
  background: white!important;
}
.write-symbol,.feature_value {
  text-align: center;
  line-height: 3;
}
.feature_value,.write-symbol{

font-size: 14px;
font-weight: 400;
line-height: 22px;
color: #667085;
justify-content: center;
    display: flex;
    align-items: center;

}
.tier_price {
  font-family: Poppins;
font-size: 32px;
font-weight: 600;
line-height: 56px;
letter-spacing: -0.02em;
text-align: center;
color: #292D35;
margin-bottom: 20px;
}
.pro_feature_value{
  position:relative;
}
.pro_feature_value:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-left: 2px solid #7F56D9;
    border-right: 2px solid #7F56D9;
    z-index: 0;
}
.tier-header-5:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 16px 16px 0 0;
    border: 2px solid #7F56D9;
    border-bottom: 0;
}
.pro_write-symbol{
  position:relative;
}
.pro_write-symbol:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-left: 2px solid #7F56D9;
    border-right: 2px solid #7F56D9;
}
 .feature_content-with-icon:last-child .feature_content_wrap .pro_write-symbol:before {
    border-radius: 0 0 16px 16px;
    border-bottom: 2px solid #7F56D9;
}
</style>
